import { useMainStore } from '~/store'
import {defineNuxtPlugin} from "nuxt/app";

export default defineNuxtPlugin(({ $pinia }) => {
  return {
    provide: {
      store: useMainStore($pinia)
    }
  }
})
