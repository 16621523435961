import {io} from 'socket.io-client'
import {Peer} from 'peerjs'
import {defineNuxtPlugin, useRuntimeConfig} from "nuxt/app";
export default defineNuxtPlugin(nuxtApp => {
  const {token} = useAuth()
  let socket = null
  let peer = null
  let currentCall = null
  let outgoingRingingCalls = [];

  const init = async () => {
    try {
      close()
      socket = io(useRuntimeConfig().public.apiUrl, {
        auth: {
          token: token.value
        }
      })
      socket.on('connect', () => {
        console.log(`Connected socket ${socket.id}`);
        nuxtApp.$store.setSocketConnected(true)
        initPeerjs()
      })
      socket.on('connect_error', () => {
        nuxtApp.$store.setSocketConnected(false)
        setTimeout(() => {
          init()
        }, 1000)
      })
      socket.on('disconnect', (_data) => {
        nuxtApp.$store.setSocketConnected(false)
        socket.connect()
      })
      socket.onAny(onAny)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *
   * PeerJs callbacks
   *
   */
  const initPeerjs = async () => {
    try {
      const iceServers = [
        {
          urls: "stun:stun.relay.metered.ca:80",
        },
        {
          urls: "turn:global.relay.metered.ca:80",
          username: "5f274469fdb5a0c561e03571",
          credential: "pPqtKYdW/gVKvIPJ",
        },
        {
          urls: "turn:global.relay.metered.ca:80?transport=tcp",
          username: "5f274469fdb5a0c561e03571",
          credential: "pPqtKYdW/gVKvIPJ",
        },
        {
          urls: "turn:global.relay.metered.ca:443",
          username: "5f274469fdb5a0c561e03571",
          credential: "pPqtKYdW/gVKvIPJ",
        },
        {
          urls: "turns:global.relay.metered.ca:443?transport=tcp",
          username: "5f274469fdb5a0c561e03571",
          credential: "pPqtKYdW/gVKvIPJ",
        },
      ];

      peer = new Peer({debug: Number(useRuntimeConfig().public.peerJsLogLevel), config: {iceServers}});
      peer.on('open', (id) => {
        console.log("PEER: OPEN");
        // console.log("Sending id " + id);
        socket.emit('peerId', id);
        peer.on('call', onIncomingCall);
        peer.on('disconnected', () => {
          console.log("PEER: DISCONNECTED");
          // Reconnect in 10 seconds
          setTimeout(() => {
            if (peer.disconnected) {
              console.log(peer);
              console.log("PEER: RECONNECTING");
              socket.emit('peerId', null);
              peer.reconnect();
            }
          }, 10000)
        });
        peer.on('error', (error) => {
          console.log("PEER ERROR : " + error.type);
        });
      })
    } catch (err) {
      console.log(err)
    }
  }

  const onIncomingCall = async (call) => {
    try {
      const callerPeerId = call.peer
      if (callerPeerId) {
        currentCall = call
        socket.emit('event', 'CALL_INCOMING_RING', {connectionId: call.connectionId, callType: call.metadata.callType, userAgent: window.navigator.userAgent}, call.metadata.caller.uuid);
        nuxtApp.$store.setCallModalData({
          callType: call.metadata.callType,
          direction : 'INCOMING',
          peerUser : call.metadata.caller,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const setupIncomingCall = (stream, streamCallback, closeCallback) => {
    if (!currentCall)
      return;
    currentCall.on("stream", streamCallback);
    currentCall.on("close", closeCallback);
    currentCall.answer(stream);
  }

  const startCall = async (userUuid, callType) => {
    const peerUser = await useMyOFetch(`/users/${userUuid}/peers`);
    if (peerUser.connected) {
      socket.emit('event', 'CALL_OUTGOING_INITIATE', {callType, userAgent: window.navigator.userAgent}, peerUser.uuid);
      nuxtApp.$store.setCallModalData({
        callType: callType,
        direction : 'OUTGOING',
        peerUser : peerUser,
      })
    } else {
      socket.emit('missedCall', { to: peerUser.uuid });
      nuxtApp.$store.setConfirmationAlert({
        title: "LB_NOT_AVAILABLE",
        message: "MSG_PLEASE_SEND_ME_A_MESSAGE",
        alertType: "info",
        btn_ok: "LB_OK",
        btn_ko: "",
      });
      nuxtApp.$store.setConfirmation(true);
    }
  }

  const setupOutgoingCall = async (user, callType, stream, streamCallback, closeCallback) => {
    try {
      if (currentCall) {
        console.log("Warning : call already in progress");
        return;
      }
      outgoingRingingCalls = [];
      const me = nuxtApp.$auth.user();
      for (const peerId of user.peerIds) {
        const call = peer.call(peerId, stream, {
          metadata:
            {
              callType: callType,
              caller: {
                id: me.id,
                uuid: me.uuid,
                fullName: me.fullName,
              }
            },
        });
        if (call) {
          outgoingRingingCalls.push(call);
          if (closeCallback) {
            call.on('close', closeCallback);
          }
          call.on('error', (error) => {
            console.log("ON ERROR");
            console.log(error);
          })
          call.on('stream', remoteStream => {
            if (!currentCall) {
              currentCall = call;
              streamCallback(remoteStream);
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const hangUp = () => {
    if (!currentCall) {
      cancelOutgoingRingingCalls();
      console.log("HANG UP: no active call")
      return;
    }
    currentCall.close();
    currentCall = null;
  }

  const cancelOutgoingRingingCalls = () => {
    if (outgoingRingingCalls.length === 0) {
      console.log("No ringing call to cancel");
      return;
    }
    for (const call of outgoingRingingCalls) {
      call.close();
    }
    currentCall = null;
  }

  const sendMissedCallEvent = () => {
    const correspondentUuid = nuxtApp.$store.callModalData?.peerUser?.uuid;
    if (correspondentUuid)
      socket.emit('missedCall', { to: correspondentUuid });
  }

  const sendEvent = (eventType, otherUserUuid) => {
    socket.emit('event', eventType, {connectionId: currentCall?.connectionId, userAgent: window.navigator.userAgent}, otherUserUuid);
  }

  /**
   *
   * Socket.io callbacks
   *
   */
  const onAny = async (eventName, ...args) => {
    try {
      console.log(eventName)
      console.log(args)
      switch (eventName) {
        case 'message':
          await onMessage(args)
          break
        default:
          break
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onMessage = async (data) => {
    try {
      if (data.length <= 0 || socket.disconnected) {
        return
      }
      switch (data[0].code) {
        case 'myConversationsResponse': {
          // Sort conversations before storing
          const myConversations = data[0].data;
          if (myConversations?.length > 1) {
            myConversations.sort((a, b) => {
              const aDate = a.lastMessage?.createdAt ?? a.createdAt;
              const bDate = b.lastMessage?.createdAt ?? b.createdAt;
              return aDate < bDate;
            });
          }
          await nuxtApp.$store.setMyConversations(myConversations);
          break;
        }
        case 'myConversationsUpdateRequested': {
          await refreshMyConversations()
          break;
        }
        case 'conversationUpdate': {
          // happens when we have just deleted a message or when we receive a message
          const currentConversationId = nuxtApp.$store.currentConversation?.id
          if (data[0].data.id && currentConversationId && (data[0].data.id === currentConversationId)) {
            // This conversation is displayed, update display
            nuxtApp.$store.setCurrentConversation(data[0].data)
          }
          break;
        }
        case 'conversationWithUserResponse': {
          nuxtApp.$store.setCurrentConversation(data[0].data);
          const existingConversationIds = nuxtApp.$store.myConversations?.map(conv => conv.id);
          if (!existingConversationIds.includes(data[0].data.id)) {
            // Conversation is new, update conversation display
            await refreshMyConversations();
          }
          break;
        }
        case 'myNotificationsResponse': {
          let transformedArray = []
          if (data[0].data) {
            const groupedNotifs = data[0].data.reduce((result, notif) => {
              const key = "" + notif.referencedUserId + notif.category
              if (!result[key]) {
                result[key] = { ...notif, count: 1 };
              } else {
                result[key].count++;
              }
              return result;
            }, {});
            // Convert the grouped object back to an array
            transformedArray = Object.values(groupedNotifs);
          }
          nuxtApp.$store.setNotifications(transformedArray);
          break;
        }
        case 'error': {
          // token error
          break;
        }
      }
    } catch (err) {
      console.log(err, data)
    }
  }

  /**
   *
   * methods used by other modules
   *
   */
  const refreshMyConversations = async () => {
    try {
      if (socket)
        await socket.emit('myConversationsRequest', {})
    } catch (err) {
      console.log(err)
    }
  }

  const refreshOneConversation = async (conversationId) => {
    try {
      setTimeout(async () => {
        await socket.emit('conversationRequest', {
          id: conversationId
        })
        await refreshMyConversations();
      }, 200)
      // 200 ms delay to limite race conditions ans make sure conversation is up to date
    } catch (err) {
      console.log(err)
    }
  }

  const getAndDisplayConversation = async (correspondentUserUuid) => {
    try {
      if (socket === null) {
        setTimeout(() => {
          getAndDisplayConversation(correspondentUserUuid)
        }, 1000)
        return false
      }
      await socket.emit('conversationWithUserRequest', {to: correspondentUserUuid })
    } catch (err) {
      console.log(err)
    }
  }

  const sendMessage = async (userUuid, type, content) => {
    try {
      const data = {
        to: userUuid,
        type: type,
        content: content
      }
      await socket.emit('sendMessage', data)
    } catch (err) {
      console.log(err)
    }
  }

  const setConversationAsRead = async (conversation) => {
    try {
      await socket.emit('markConversationAsRead', conversation.id)
      await refreshOneConversation(conversation.id);
    } catch (err) {
      console.log(err)
    }
  }

  const deleteMessage = async (message) => {
    try {
      await socket.emit('deleteMessage', message.id)
      await refreshOneConversation(message.conversationId);
    } catch (err) {
      console.log(err)
    }
  }

  const refreshNotifications = async () => {
    try {
      if (socket)
        await socket.emit('myNotificationsRequest', {})
    } catch (err) {
      console.log(err)
    }
  }

  const setNotificationAsRead = async (notification) => {
    try {
      await useMyOFetch(`/messages/notifications/acknowledge/${notification.id}`, {method: 'PUT'});
      await refreshNotifications();
    } catch (err) {
      console.log(err)
    }
  }

  const isConnected = () => {
    try {
      return socket && socket.connected
    } catch (err) {
      console.log(err)
      return false
    }
  }

  const close = () => {
    try {
      if (socket)
        socket.disconnect()
    } catch (err) {
      console.log(err)
    }
  }

  const tryToReconnect = () => {
    try {
      socket.connect()
    } catch (err) {
      console.log(err)
    }
  }

  return {
    provide: {
      chat: {
        init,
        startCall,
        setupIncomingCall,
        setupOutgoingCall,
        hangUp,
        cancelOutgoingRingingCalls,
        sendMissedCallEvent,
        sendEvent,
        refreshMyConversations,
        getAndDisplayConversation,
        sendMessage,
        setConversationAsRead,
        deleteMessage,
        refreshNotifications,
        setNotificationAsRead,
        isConnected,
        close,
        tryToReconnect,
      }
    }
  }
})
