// import that after install `@mdi/font` package
import '@mdi/font/css/materialdesignicons.css'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import {defineNuxtPlugin} from "nuxt/app";
// import colors from 'vuetify/util/colors'

// const myCustomLightTheme = {
//   dark: false,
//   colors: {
//     // background: '#FFFFFF',
//     // surface: '#FFFFFF',
//     primary: colors.blue.darken2,
//     accent: colors.grey.darken3,
//     secondary: colors.amber.darken3,
//     info: colors.teal.lighten1,
//     warning: colors.amber.base,
//     error: colors.deepOrange.accent4,
//     success: colors.green.accent3,
//   },
// }

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    // ... your configuration
    // theme: {
    //   defaultTheme: 'myCustomLightTheme',
    //   themes: {
    //     myCustomLightTheme,
    //   },
    // },
  })
  app.vueApp.use(vuetify)
})
