import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  // const router = useRouter()
  const { public: { sentry } } = useRuntimeConfig()

  if (!sentry.dsn) {
    console.log("Warning : no DSN defined for Sentry");
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],

    tracesSampleRate: 0.2, // Change in prod

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https://app.staging.beegup.com', 'https://app.beegup.com'],

    replaysSessionSampleRate: 0.01, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
  })
})
