import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    isSocketConnected: false,
    counter: 0,
    userImageCounter: 0,

    showExternalLinkDialog: false,
    hasNotAcceptedExternalLinksYet: true,
    externalLinkUrl: "",

    showConfirmation: false,
    confirmationAlert: {
      title: '',
      message: '',
      alertType: "info",
      btn_ok: 'LB_YES',
      btn_ko: 'LB_NO',
      okFn: null,
      koFn: null
    },

    currentConversation: null,
    myConversations: [],
    notifications: [],

    showCallModal: false,
    callModalData: null,

    showExam: false,
    showSharePost: false,
    sharePostData: null,

    currentWorkGroupName: null,
    currentContentFolder: "all",
    currentContentCategory: "",

    myCommunitySelectedTab: 0,
    selectedNativeLanguages: [],
    selectedLanguages: [],
    selectedCountries: [],
    selectedDomains: [],
    selectedClassLevels: [],
    selectedInterests: [],
  }),
  getters: {},
  actions: {
    setSocketConnected (state: boolean) {
      this.isSocketConnected = state
    },
    incrementCounter() {
      this.counter++
    },
    incrementUserImage() {
      this.userImageCounter++
    },

    openExternalLinkDialog(value) {
      this.externalLinkUrl = value;
      this.showExternalLinkDialog = true;
    },
    closeExternalLinkDialog() {
      this.showExternalLinkDialog = false;
    },
    acceptExternalLinksForSession() {
      this.hasNotAcceptedExternalLinksYet = false;
    },

    setConfirmation(value: boolean) {
      this.showConfirmation = value
      if (!value) {
        this.confirmationAlert = {
          title: '',
          message: '',
          alertType: "info",
          btn_ok: 'LB_YES',
          btn_ko: 'LB_NO',
          okFn: null,
          koFn: null
        }
      }
    },
    setConfirmationAlert(data) {
      this.confirmationAlert = data
      this.counter++
    },

    setMyConversations(value) {
      this.myConversations = value
      this.counter++
    },
    setCurrentConversation(value) {
      this.currentConversation = value
    },
    setNotifications(value) {
      this.notifications = value
    },

    setCallModalData(data) {
      this.callModalData = data
      this.showCallModal = !!data;
    },

    setShowExam(value: boolean) {
      this.showExam = value
    },
    setShowSharePost ({value, data}) {
      this.showSharePost = value
      this.sharePostData = data
    },

    setCurrentWorkGroupName(name){
      this.currentWorkGroupName = name
    },
    setCurrentContentCategory(value){
      this.currentContentCategory = value
    },
    setCurrentContentFolder(value){
      this.currentContentFolder = value
    },
  },
})
