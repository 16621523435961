import {getUserImage} from "~/utils";

export default defineNuxtPlugin((_nuxtApp) => {
  const { public: { crispWebsiteId } } = useRuntimeConfig()

  window.$crisp = [];
  window.CRISP_WEBSITE_ID = crispWebsiteId;

  if (crispWebsiteId) {
    (function() {
      const d = document;
      const s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  } else {
    console.log("No Crisp Website id defined");
  }

  // const onChatClosed = () => {
  //   window.$crisp.push(['do', 'chat:hide']);
  // }
  //
  // window.$crisp.push(["on", "chat:closed", onChatClosed]);

  const setUser = (user) => {
    if (user.email)
      window.$crisp.push(["set", "user:email", [user.email]]);
    window.$crisp.push(["set", "user:nickname", [user.fullName]]);
    window.$crisp.push(["set", "user:company", [user.establishment?.name, {
      description: user.establishment?.code,
      employment: ["-", user.role],
      geolocation: [user.establishment?.region?.country?.iso, user.establishment?.city]
    }]]);
    window.$crisp.push(["set", "session:data", ["id", user.id]]);
    window.$crisp.push(["set", "session:data", ["uuid", user.uuid]]);
    window.$crisp.push(["set", "session:data", ["role", user.role]]);
    window.$crisp.push(["set", "session:data", ["pays", user.establishment?.region?.country?.name]]);
    window.$crisp.push(["set", "user:avatar", [getUserImage(user)]]);
  }

  const openChat = () => {
    window.$crisp.push(['do', 'chat:show']);
    window.$crisp.push(['do', 'chat:open']);
  }

  return {
    provide: {
      crisp: {setUser, openChat},
    }
  }
})
