import {useRuntimeConfig} from "nuxt/app";
export const useMyOFetch = (request, opts) => {
  const config = useRuntimeConfig()
  const {token} = useAuth()

  return $fetch(request, {
    baseURL: config.public.apiUrl,
    headers: {
      Authorization: `${token.value}`,
    },
    ...opts })
}
