import {defineNuxtPlugin} from "nuxt/app";

export default defineNuxtPlugin(_nuxtApp => {
  const {status, data, token, getSession, signIn, signOut} = useAuth()

  const user = () => {
    return data.value?.user;
  }
  return {
    provide: {
      auth: {
        status,
        data,
        token,
        getSession,
        signIn,
        signOut,
        user
      }
    }
  }
})

// export default ({ app }) => {
//   app.router.beforeEach(async (to, from, next) => {
//     const isAuthenticated = app.$auth.$state.loggedIn;
//     if ((to.name === 'index' || to.name === 'landing') && !isAuthenticated) {
//       const locale = app.i18n.locale;
//       const redirectPath = `/${locale}/landing`;
//       window.location.href = redirectPath;
//       return;
//     }
//     next();
//   });
// };
