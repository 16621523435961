import {useRuntimeConfig} from "nuxt/app";

export const formatLongDate = (dateStr, locale) => {
  const date = new Date(dateStr);

  const options = {
    weekday: "long", // Full weekday name (e.g., "lundi")
    day: "numeric", // Day of the month (e.g., "17")
    month: "long", // Full month name (e.g., "juillet")
    year: "numeric", // Full year (e.g., "2023")
  };

  const formatter = new Intl.DateTimeFormat(locale, options);
  const formattedDate = formatter.format(date);
  return formattedDate;
};

export const formatShortDate = (dateStr, locale) => {
  if (!dateStr) {
    return "";
  }
  const date = new Date(dateStr);

  const options = {
    day: "numeric", // Day of the month (e.g., "17")
    month: "short", // Shor month (e.g. "Oct")
    year: "numeric", // Full year (e.g., "2023")
  };

  const formatter = new Intl.DateTimeFormat(locale, options);
  const formattedDate = formatter.format(date);
  return formattedDate;
};

export const formatShortDateTime = (dateStr, locale) => {
  if (!dateStr) {
    return "";
  }
  const date = new Date(dateStr);

  const options = {
    day: "numeric", // Day of the month (e.g., "17")
    month: "short", // Shor month (e.g. "Oct")
    year: "numeric", // Full year (e.g., "2023")
    hour: "numeric",
    minute: "numeric"

  };

  const formatter = new Intl.DateTimeFormat(locale, options);
  const formattedDate = formatter.format(date);
  return formattedDate;
};

export const extractIsoDate = (dateStr) => {
  return dateStr.substr(0,10);
};

export const getFlagPath = (iso) => {
  if (iso)
    return `/images/flags/${iso}.svg`;
  else
    return "";
};

export const getUserImage = (user) => {
  if (user?.image)
    return useRuntimeConfig().public.apiUrl + '/images/' + user.image;
  else
    return useRuntimeConfig().public.frontUrl + '/images/default_avatar.png';
}

export const isStringSet = (str) => {
  return (str && str.length>0);
}

export const openLink = (url, isExternal) => {
  const me = useNuxtApp().$auth.user();
  if (isExternal && me?.role === 'STUDENT' && useNuxtApp().$store.hasNotAcceptedExternalLinksYet)
    useNuxtApp().$store.openExternalLinkDialog(url);
  else
    window.open(url, "_blank");
}

export const shuffleArray = (array) => {
  return array.sort((_a, _b) => 0.5 - Math.random());
};

